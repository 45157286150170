import { ReconnectingWebSocket } from "../helpers-0.0.1/reconnecting-websocket.js";

export class WebSocketAdaptor {
  constructor(initialValues) {
    this.debug = false;

    for (var key in initialValues) {
      if (initialValues.hasOwnProperty(key)) {
        this[key] = initialValues[key];
      }
    }
    this.wsConn = new ReconnectingWebSocket(this.websocket_url, [], {
      debug: false,
    });

    this.isWebSocketTriggered = true;
    this.connected = false;
    this.pingTimerId = -1;

    this.wsConn.onopen = () => {
      console.log("websocket connected");

      this.pingTimerId = setInterval(() => {
        this.sendPing();
      }, 3000);

      this.connected = true;
      this.errorHappened = false;
      this.callback("connected");
    };

    this.wsConn.onmessage = (event) => {
      var obj = JSON.parse(event.data);

      if (obj.command === "start") {
        //this command is received first, when publishing so playmode is false

        if (this.debug) {
          console.debug("received start command");
        }

        this.webrtcadaptor.startPublishing(obj.streamId);
      } else if (obj.command === "takeCandidate") {
        if (this.debug) {
          console.debug("received ice candidate for stream id " + obj.streamId);
          console.debug(obj.candidate);
        }

        this.webrtcadaptor.takeCandidate(
          obj.streamId,
          obj.label,
          obj.candidate
        );
      } else if (obj.command === "takeConfiguration") {
        if (this.debug) {
          console.log(
            "received remote description type for stream id: " +
              obj.streamId +
              " type: " +
              obj.type
          );
        }
        this.webrtcadaptor.takeConfiguration(obj.streamId, obj.sdp, obj.type);
      } else if (obj.command === "stop") {
        console.debug("Stop command received");
        this.webrtcadaptor.closePeerConnection(obj.streamId);
      } else if (obj.command === "error") {
        this.callbackError(obj.definition);
      } else if (obj.command === "notification") {
        this.callback(obj.definition, obj);
        if (
          obj.definition === "play_finished" ||
          obj.definition === "publish_finished"
        ) {
          this.webrtcadaptor.closePeerConnection(obj.streamId);
        }
      } else if (obj.command === "streamInformation") {
        this.callback(obj.command, obj);
      } else if (obj.command === "roomInformation") {
        this.callback(obj.command, obj);
      } else if (obj.command === "pong") {
        this.callback(obj.command);
      } else if (obj.command === "requestLocalCapture") {
        this.callback(obj.command);
      } else if (obj.command === "requestLocalStreamStats") {
        this.callback(obj.command);
      } else if (obj.command === "notifyNoPermissionsForCapture") {
        this.callback(obj.command);
      } else if (obj.command === "trackList") {
        this.callback(obj.command, obj);
      } else if (obj.command === "connectWithNewId") {
        this.multiPeerStreamId = obj.streamId;
        this.join(obj.streamId);
      } else if (obj.command === "peerMessageCommand") {
        this.callback(obj.command, obj);
      }
    };

    this.wsConn.onerror = () => {
      console.warn("Websocket error event");
      this.clearPingTimer();
    };

    this.wsConn.onconnecting = () => {
      console.debug("Websocket connecting");
      this.callback("connecting");
    };

    this.wsConn.onclose = (event) => {
      if (!this.connected) {
        console.error(
          "Websocket connection closed abruptly with code: " + event.code
        );
        this.callbackError("WebSocketError");
      } else {
        this.connected = false;
        console.log("websocket connection closed.");
        this.callback("websocket_connection_closed", event);
      }

      this.clearPingTimer();
    };
  }

  clearPingTimer() {
    if (this.pingTimerId !== -1) {
      if (this.debug) {
        console.debug("Clearing ping message timer");
      }
      clearInterval(this.pingTimerId);
      this.pingTimerId = -1;
    }
  }

  sendPing() {
    var jsCmd = {
      command: "ping",
    };
    this.wsConn.send(JSON.stringify(jsCmd));
  }

  close() {
    this.wsConn.close();
  }

  // changeUrlAndRefresh(url) {
  //     this.wsConn.url = url
  //     this.wsConn.refresh();
  // }

  refresh() {
    this.wsConn.refresh();
  }

  send(text) {
    if (this.wsConn.readyState !== WebSocket.OPEN) {
      this.callbackError("WebSocketNotConnected");
      return;
    }
    this.wsConn.send(text);
  }

  isConnected() {
    return this.connected;
  }
}
